import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Efter 20 år kanske vi får version 3 av Gimp, Tim Berners-Lee försöker att överträffa sig själv med Inrupt och Solid. Vad hände med Vim egentligen? Det och mycket mer! `}</p>
    <h2>{`Rättning`}</h2>
    <ul>
      <li parentName="ul">{`RaspberryPi har 4 kärnor, inte 8.`}</li>
    </ul>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Inrupt/Solid: `}<a parentName="li" {...{
          "href": "https://www.cnet.com/news/tim-berners-lee-startup-launches-privacy-focused-service-to-secure-your-data/"
        }}>{`https://www.cnet.com/news/tim-berners-lee-startup-launches-privacy-focused-service-to-secure-your-data/`}</a></li>
      <li parentName="ul">{`Testa Gimp 3.0-features: `}<a parentName="li" {...{
          "href": "https://www.gimp.org/news/2020/11/06/gimp-2-99-2-released/"
        }}>{`https://www.gimp.org/news/2020/11/06/gimp-2-99-2-released/`}</a></li>
    </ul>
    <h3>{`Kort & Gott`}</h3>
    <ul>
      <li parentName="ul">{`Facelift av Plasmas Systemövervakare: `}<a parentName="li" {...{
          "href": "https://quantumproductions.info/articles/2020-2020-11/plasma-system-monitor-preview-release"
        }}>{`https://quantumproductions.info/articles/2020-2020-11/plasma-system-monitor-preview-release`}</a></li>
      <li parentName="ul">{`Uppgradera PinePhones hårdvara: `}<a parentName="li" {...{
          "href": "https://pine64.com/product/pinephone-community-edition-3gb-32gb-mainboard-special-offer-for-braveheart-and-ubports-owners/?v=0446c16e2e66"
        }}>{`https://pine64.com/product/pinephone-community-edition-3gb-32gb-mainboard-special-offer-for-braveheart-and-ubports-owners/?v=0446c16e2e66`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`npm-paket stjäl uppgifter: `}<a parentName="li" {...{
          "href": "https://www.zdnet.com/article/npm-package-caught-stealing-sensitive-discord-and-browser-files/"
        }}>{`https://www.zdnet.com/article/npm-package-caught-stealing-sensitive-discord-and-browser-files/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`vim-mario: `}<a parentName="li" {...{
          "href": "https://github.com/rbtnn/vim-mario"
        }}>{`https://github.com/rbtnn/vim-mario`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`Alex rustäventyr: milestones satta `}</li>
      <li parentName="ul">{`Hur gick det med Vim egentligen? `}<ul parentName="li">
          <li parentName="ul">{`Sebs vim config: `}<a parentName="li" {...{
              "href": "https://github.com/sebastiangelotte/dotfiles/tree/master/.config/nvim"
            }}>{`https://github.com/sebastiangelotte/dotfiles/tree/master/.config/nvim`}</a></li>
          <li parentName="ul">{`Alex vim config: `}<a parentName="li" {...{
              "href": "https://github.com/Alekzanther/dotfiles/tree/master/.config/nvim"
            }}>{`https://github.com/Alekzanther/dotfiles/tree/master/.config/nvim`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Kapitel fungerade! (tack Fredrik Björeman m.fl.)`}<ul parentName="li">
          <li parentName="ul">{`Podcast Chapters: `}<a parentName="li" {...{
              "href": "https://chaptersapp.com/"
            }}>{`https://chaptersapp.com/`}</a></li>
          <li parentName="ul">{`Podden Björeman // Melin: `}<a parentName="li" {...{
              "href": "https://www.bjoremanmelin.se/index.html"
            }}>{`https://www.bjoremanmelin.se/index.html`}</a></li>
          <li parentName="ul">{`Podden Kodsnack: `}<a parentName="li" {...{
              "href": "https://kodsnack.se/"
            }}>{`https://kodsnack.se/`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Backbay Lounge" by Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      